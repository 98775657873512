
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import TerritoryPicker from "@/components/TerritoryPicker.vue";
import { departamentos } from '@/modules/cyanRegions'
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "PickMpio",
  components: {
    TerritoryPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed() {
      return unreact((this as any).$route.params.seedPM+'') || 'SV'
    }, 

    title() {
			if (this.seed in departamentos) {
				return 'Elegir cantón (' + departamentos[(this as any).seed].nombre + ')';
			}
			return 'Elegir cantón';
		},
		userLevel() {
      return store.state.userData.nivel || '';
    },
		backRoute() {
			return ((this as any).userLevel != 'SV') ? '/partyLevels' : '/pickDeptoMpio'
		},

  },
  methods: {},
});
